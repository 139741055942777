import { useAuth } from "@behaviour-lab/blab-component-library/auth"
import useSwr, { useSWRConfig } from "swr"

import { UserType } from "src/types"

import { getFetcher, ApiUrl } from "./fetcher/instances"
import { fetcherOptions } from "./fetcher/options"

export const useGetInvokerSWR = () => {
  const { logout } = useAuth()

  return useSwr<UserType, string>(
    "/invoker",
    url =>
      getFetcher(ApiUrl.NEXT_PUBLIC_CRUD_API, { url, tokenExpiredCb: logout }),
    fetcherOptions,
  )
}

export const useRevalidateInvoker = () => {
  const { mutate } = useSWRConfig()

  return {
    triggerRevalidateInvoker: () => {
      mutate("/invoker")
    },
  }
}
