import * as React from "react"

import { useAuth } from "@behaviour-lab/blab-component-library/auth"

import { formatInitialsFromUser } from "src/lib/common"
import { useGetInvokerSWR } from "src/lib/swr/invoker"
import { UserOrgRoleEnum, UserProductPermissionEnum, UserType } from "src/types"

export const useInvoker = (): {
  isLoading: boolean
  error: string | undefined
  invoker: UserType | undefined
  userEmail: string | undefined
  isSuperAdmin: boolean
  initials: string
  userRolesInOrgsMap: Record<number, UserOrgRoleEnum>
  userPolygonPermissionsInPortfoliosMap: Record<
    number,
    UserProductPermissionEnum
  >
} => {
  const { isSuperAdmin, user } = useAuth()
  const { data, isLoading, error } = useGetInvokerSWR()

  // Here we create a structure to access user's role in the organizations fast: { 1: "Member", 345: "AccountManager"}
  const userRolesInOrgsMap = React.useMemo(
    () =>
      isSuperAdmin
        ? {}
        : data?.organizations
            ?.map(({ organizationUsers }) => organizationUsers)
            .flat()
            .reduce((acc: Record<number, UserOrgRoleEnum>, orgUser) => {
              if (orgUser?.user?.email === user?.email) {
                acc[orgUser.organizationId] = orgUser.role
              }

              return acc
            }, {}) || {},
    [isSuperAdmin, data, user?.email],
  )

  // Here we create a structure to access invoker's role in the portfolio fast: { 1: "View", 345: "Edit"}
  const userPolygonPermissionsInPortfoliosMap = React.useMemo(
    () =>
      isSuperAdmin
        ? {}
        : data?.accessiblePortfolios?.reduce(
            (acc: Record<number, UserProductPermissionEnum>, portfolio) => {
              if (portfolio.userPermission?.polygonPermission) {
                acc[portfolio.id] = portfolio.userPermission?.polygonPermission
              }

              return acc
            },
            {},
          ) || {},
    [isSuperAdmin, data],
  )

  if (isSuperAdmin) {
    return {
      isLoading,
      error,
      invoker: data,
      userEmail: user?.email as string,
      isSuperAdmin,
      initials: data ? formatInitialsFromUser(data) : "",
      userRolesInOrgsMap,
      userPolygonPermissionsInPortfoliosMap,
    }
  }

  return {
    isLoading,
    error,
    invoker: data,
    userEmail: user?.email as string,
    isSuperAdmin,
    initials: data ? formatInitialsFromUser(data) : "",
    userRolesInOrgsMap,
    userPolygonPermissionsInPortfoliosMap,
  }
}
