import { useAuth } from "@behaviour-lab/blab-component-library/auth"
import useSWR, { useSWRConfig } from "swr"

import { PluginNameEnum, SettingsResponseType } from "src/types"

import { getDataLake } from "../common"

import { getFetcher, ApiUrl, postMutator } from "./fetcher/instances"
import { fetcherOptions } from "./fetcher/options"

export const usePortfolioSettingsSWR = (orgId: number, portfolioId: number) => {
  const { logout } = useAuth()

  return useSWR(
    `/analytics/settings/${orgId}/${portfolioId}`,
    url =>
      getFetcher(ApiUrl.NEXT_PUBLIC_ANALYTICS_API, {
        url,
        tokenExpiredCb: logout,
      }),
    fetcherOptions,
  )
}

// TODO: check if there are any scenarios under which we need to invalidate the swr cache for portfolio categories
export const usePortfolioCategorySWR = (
  orgId: number,
  portfolioId: number,
  benchmarkSedol?: string,
) => {
  const { logout } = useAuth()
  const url = "/analytics/coordinator"

  return useSWR(
    orgId && portfolioId && benchmarkSedol
      ? `/${orgId}/${portfolioId}/${PluginNameEnum.CATEGORIES}`
      : null,
    () =>
      postMutator(ApiUrl.NEXT_PUBLIC_ANALYTICS_API, {
        url,
        tokenExpiredCb: logout,
        options: {
          arg: {
            bucket: getDataLake(),
            client: `${orgId}`,
            portfolio: `${portfolioId}`,
            plugin: PluginNameEnum.CATEGORIES,
            parameters: { benchmark_sedol: benchmarkSedol },
          },
        },
      }),
    fetcherOptions,
  )
}

export const useRevalidatePortfolioSettingsSWR = (
  orgId: number,
  portfolioId: number,
) => {
  const { mutate } = useSWRConfig()

  return {
    triggerRevalidatePortfolioSettings: (
      updatedParameters: SettingsResponseType,
    ) => {
      mutate(
        `/analytics/settings/${orgId}/${portfolioId}`,
        () => updatedParameters,
        false,
      ) // false here means to not revalidate data with the remote API
    },
    triggerCleanUpParameters: () =>
      mutate(`/analytics/settings/${orgId}/${portfolioId}`, undefined, false), // false here means to not revalidate data with the remote API
  }
}
