import { GoldenDataLakeEnum } from "src/api/types"

/**
 * Abstraction that gets the correct data lake for the current environment
 * @returns {GoldenDataLakeEnum} - The data lake we want the analytics to use
 */
export const getDataLake = (): GoldenDataLakeEnum => {
  switch (process.env.NEXT_PUBLIC_TARGET_ENV) {
    case "production":
      return GoldenDataLakeEnum.GoldenDataLakeProd
    case "staging":
      return GoldenDataLakeEnum.GoldenDataLakeStage
    case "development":
      return GoldenDataLakeEnum.GoldenDataLakeDev
    case "local":
      return GoldenDataLakeEnum.GoldenDataLakeLocal
    default:
      return GoldenDataLakeEnum.GoldenDataLakeDev
  }
}
