import * as React from "react"

import { useAuth } from "@behaviour-lab/blab-component-library/auth"

import { OrganizationType, UserProductPermissionEnum } from "src/types"

import { useCanViewSettings } from "./useCanViewSettings"

export const useConfiguredViewEditPolygonPortfolios = (
  activeOrg?: OrganizationType,
) => {
  const { user } = useAuth()
  const canViewSettings = useCanViewSettings(activeOrg?.id)

  // Here we filter the portfolios:
  // 1. The portfolios with setup polygon
  // 2. a) sadmin &&  AM && Owner === canViewSettings - all portfolios
  //    b) user has view or edit permission for polygon
  const portfoliosWithViewEditPermissions = React.useMemo(() => {
    return (
      activeOrg?.portfolios.filter(portfolio => {
        if (!portfolio.productPolygon) return false
        if (canViewSettings) return true

        const userPolygonPermission = portfolio.usersPermissions?.find(
          u => u.email === user?.email,
        )?.userToPortfolioPermissions?.polygonPermission

        const hasUserPermissionToViewPolygon =
          !!userPolygonPermission &&
          [
            UserProductPermissionEnum.View,
            UserProductPermissionEnum.Edit,
          ].includes(userPolygonPermission)

        return hasUserPermissionToViewPolygon
      }) || []
    )
  }, [activeOrg, canViewSettings, user?.email])

  return portfoliosWithViewEditPermissions
}
