export type ResponseWrapper<T> = {
  data: T
}

export enum GoldenDataLakeEnum {
  GoldenDataLakeProd = "main-prod-golden-data-lake",
  GoldenDataLakeStage = "main-stage-golden-data-lake",
  GoldenDataLakeDev = "main-dev-golden-data-lake",
  GoldenDataLakeLocal = "main-local-golden-data-lake",
}
