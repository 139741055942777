import * as React from "react"

import { usePortfolioConstituentLookupTableSWR } from "src/lib/swr/portfolioLookupTable"
import { usePortfolioSettingsSWR } from "src/lib/swr/settings"

interface IProps {
  stockId: string
  orgId: number
  portfolioId: number
}

export const useStockPageData = ({ stockId, orgId, portfolioId }: IProps) => {
  const {
    data: portfolioSettings,
    isLoading: isPortfolioSettingsLoading,
    error: portfolioSettingsError,
  } = usePortfolioSettingsSWR(orgId, portfolioId)

  const benchmarkSedol = portfolioSettings?.parameters?.benchmark_sedol

  const {
    data: lookupTable,
    isLoading: isLookupTableLoading,
    error: lookupTableError,
  } = usePortfolioConstituentLookupTableSWR({
    orgId,
    portfolioId,
    benchmarkSedol,
  })

  const sortedLookUpTableEntries = lookupTable
    ? Object.entries(Object.assign({}, lookupTable)).sort((a, b) => {
        const nameA = (a[1] as string)?.toUpperCase().trim()
        const nameB = (b[1] as string)?.toUpperCase().trim()

        if (!nameA && !nameB) return 0

        // This is needed to place empty string below
        if (!nameA && nameB) {
          return 1
        }

        // This is needed to place empty string below
        if (nameA && !nameB) {
          return -1
        }

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }

        return 0
      })
    : []

  const [currentStockIndex, setCurrentStockIndex] = React.useState<number>(0)
  const [prevStockLink, setPrevStockLink] = React.useState<string>("")
  const [nextStockLink, setNextStockLink] = React.useState<string>("")

  const isLoading = isLookupTableLoading || isPortfolioSettingsLoading
  const error = lookupTableError || portfolioSettingsError

  // Whenever the current stock index changes, update the previous and next stock links
  React.useEffect(() => {
    if (isLoading) return

    if (sortedLookUpTableEntries?.length && !error) {
      const stockIds = sortedLookUpTableEntries.map(entry => entry[0])
      const currentStockIndex = stockIds.indexOf(stockId)

      if (currentStockIndex === -1) return

      const prevStockIndex =
        currentStockIndex > 0 ? currentStockIndex - 1 : stockIds.length - 1
      const nextStockIndex =
        currentStockIndex === stockIds.length - 1 ? 0 : currentStockIndex + 1

      const prevStockLink = `/${orgId}/${portfolioId}/stocks/${stockIds[prevStockIndex]}`
      const nextStockLink = `/${orgId}/${portfolioId}/stocks/${stockIds[nextStockIndex]}`

      setCurrentStockIndex(currentStockIndex)
      setPrevStockLink(prevStockLink)
      setNextStockLink(nextStockLink)
    } else {
      setCurrentStockIndex(0)
      setPrevStockLink("")
      setNextStockLink("")
    }
  }, [stockId, sortedLookUpTableEntries, isLoading, error])

  return {
    lookupTable,
    orgId,
    portfolioId,
    stockName: lookupTable?.[stockId],
    currentStockIndex,
    prevStockLink,
    nextStockLink,
    error,
    isLoading,
  }
}
