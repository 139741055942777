import * as React from "react"

import { useAuth } from "@behaviour-lab/blab-component-library/auth"

import { OrganizationType, PortfolioType } from "src/types"
import { useCanEditSettings } from "src/utils/hooks/useCanEditSettings"
import { useCanViewSettings } from "src/utils/hooks/useCanViewSettings"
import { useInvoker } from "src/utils/hooks/useInvoker"
import { checkIsUserAllowedToViewPolygon } from "src/utils/user"

type PortfolioContextProps = {
  portfolio: PortfolioType
  activeOrg?: OrganizationType
  canEditSettings: boolean
  canViewSettings: boolean
  userCanViewOrEditPolygon: boolean
  isLoading: boolean
  error?: string
}

interface IPortfolioContextProviderProps {
  portfolio: PortfolioType
  children: React.ReactNode
}

const PortfolioContext = React.createContext<PortfolioContextProps | undefined>(
  undefined,
)

function PortfolioContextProvider({
  portfolio,
  children,
}: IPortfolioContextProviderProps) {
  const { isSuperAdmin, user } = useAuth()
  const orgId = portfolio.clientId

  const {
    invoker,
    isLoading: isLoadingInvoker,
    error: errorInvoker,
  } = useInvoker()

  const activeOrg = React.useMemo(() => {
    return invoker?.organizations?.find(org => org.id === +orgId)
  }, [orgId, invoker])

  const canEditSettings = useCanEditSettings(activeOrg?.id)

  const userCanViewOrEditPolygon = checkIsUserAllowedToViewPolygon({
    portfolio,
    isSuperAdmin,
    userEmail: user?.email as string,
  })

  const canViewSettings = useCanViewSettings(
    activeOrg?.id,
    userCanViewOrEditPolygon,
  )

  const isLoading = isLoadingInvoker
  const error = errorInvoker

  return (
    <PortfolioContext.Provider
      value={{
        portfolio: portfolio,
        activeOrg,
        canEditSettings,
        canViewSettings,
        userCanViewOrEditPolygon,
        isLoading,
        error,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  )
}

const usePortfolioContext = () => {
  const context = React.useContext(PortfolioContext)

  if (context === undefined) {
    throw new Error(
      "usePortfolioContext must be used within a PortfolioContextProvider",
    )
  }

  return context
}

export { PortfolioContextProvider, usePortfolioContext }
