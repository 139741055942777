import { usePortfolioContext } from "src/context/portfolio-context"

// This hook is used to attach orgId and portfolioID to internal pages routes
export const useInternalRoutes = (suffix: string) => {
  const { portfolio } = usePortfolioContext()

  return {
    route: `/${portfolio.clientId}/${portfolio.id}/${suffix}`,
  }
}
