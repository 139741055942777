import * as React from "react"

import { UserOrgRoleEnum } from "src/types"

import { useInvoker } from "./useInvoker"

export const useCanEditSettings = (activeOrgId?: number) => {
  const { userRolesInOrgsMap, userEmail, isSuperAdmin } = useInvoker()

  const canEditSettings = React.useMemo(() => {
    if (!activeOrgId) return false
    if (isSuperAdmin) return true

    const userRole = userRolesInOrgsMap[activeOrgId]
    return (!!userRole && userRole === UserOrgRoleEnum.AccountManager) || false
  }, [activeOrgId, userEmail, isSuperAdmin, userRolesInOrgsMap])

  return canEditSettings
}
