import { UserType, PortfolioType, UserProductPermissionEnum } from "src/types"

export const formatInitialsFromUser = (user: UserType) => {
  return (
    (user.firstName || "")?.charAt(0) + (user.lastName || "")?.charAt(0)
  ).toUpperCase()
}

export const formatFullNameFromUser = (user: UserType): string => {
  return (user.firstName || "") + " " + (user.lastName || "")
}

export const checkIsUserAllowedToViewPolygon = ({
  portfolio,
  isSuperAdmin,
  userEmail,
}: {
  portfolio?: PortfolioType
  isSuperAdmin: boolean
  userEmail?: string
}): boolean => {
  // If there is not productPolygon setup, then nobody can see polygon product
  if (!portfolio || !portfolio.productPolygon) return false
  if (isSuperAdmin) return true

  const userPolygonPermission = portfolio.usersPermissions?.find(
    u => u.email === userEmail,
  )?.userToPortfolioPermissions?.polygonPermission

  const isViewEditPermission =
    !!userPolygonPermission &&
    [UserProductPermissionEnum.Edit, UserProductPermissionEnum.View].includes(
      userPolygonPermission,
    )

  return isViewEditPermission
}

export const getUsersWithViewEditPermissions = (portfolio: PortfolioType) => {
  return (portfolio.usersPermissions || []).reduce((acc, u: UserType) => {
    // Do not show sadmin user in the stacked-avatars
    if (u.email === "admin@behaviourlab.com") return acc

    // Do not show users without permission or with None permission
    if (
      !u.userToPortfolioPermissions?.polygonPermission ||
      u.userToPortfolioPermissions?.polygonPermission ===
        UserProductPermissionEnum.None
    )
      return acc

    acc.push(u)

    return acc
  }, [] as UserType[])
}
