import * as React from "react"

import { UserOrgRoleEnum } from "src/types"

import { useInvoker } from "./useInvoker"

export const useCanViewSettings = (
  activeOrgId?: number,
  canViewOrEditPolygon?: boolean,
) => {
  const { isSuperAdmin, userEmail, userRolesInOrgsMap } = useInvoker()

  const canViewSettings = React.useMemo(() => {
    if (!activeOrgId) return false
    if (isSuperAdmin) return true

    const userRoleInOrg = userRolesInOrgsMap[activeOrgId]
    return (
      (!!userRoleInOrg &&
        [UserOrgRoleEnum.AccountManager, UserOrgRoleEnum.Owner].includes(
          userRoleInOrg,
        )) ||
      (!!userRoleInOrg &&
        userRoleInOrg === UserOrgRoleEnum.Contributor &&
        canViewOrEditPolygon) ||
      false
    )
  }, [activeOrgId, userEmail, isSuperAdmin, userRolesInOrgsMap])

  return canViewSettings
}
