import { SelectOptionType } from "@behaviour-lab/blab-component-library/components"
import {
  ScatterPlotDataItemType,
  TimingGraphDataPoint,
  VerticalBarDataItemType as VerticalBarDataItemTypeLib,
} from "@behaviour-lab/blab-component-library/visualizations"

import { StockTableItemType as StockTableItemTypeFromPluginInterface } from "src/components/Section/DataQuery/pluginResponseValidation/pluginResponseTypes"

// This is the list of parameters that the Frontend expects
// This must be in sync with blab-serverless-analytics at all times
export enum ParamNameEnum {
  BENCHMARK_SEDOL = "benchmark_sedol",
  RAMP_LENGTH = "ramp_length",
  BUY_SELECTION_PERFORMANCE_THRESHOLD = "buy_selection_performance_threshold",
  BUY_TIMING_PERFORMANCE_THRESHOLD = "buy_timing_performance_threshold",
  SELL_TIMING_PERFORMANCE_THRESHOLD = "sell_timing_performance_threshold",
  BUY_SELECTION_HORIZON = "buy_selection_horizon",
  BUY_TIMING_HORIZON = "buy_timing_horizon",
  SELL_TIMING_HORIZON = "sell_timing_horizon",
  MINIMUM_WEIGHT_CHANGE = "minimum_weight_change",
  UNDERWATER_DEPTH = "underwater_depth",
  MAX_FLOW_PARAMETER = "max_flow_parameter",
  VOLATILITY_LOOKBACK_WINDOW = "volatility_lookback_window",
  SMOOTHER = "smoother",
  UNDERWATER_LIMIT = "underwater_limit",
  FALLING_KNIFE_DROP = "falling_knife_drop",
  FK_LOOKBACK_WINDOW = "fk_lookback_window",
  ROUND_TRIP_THRESHOLD = "round_trip_threshold",
  ROUND_TRIP_LOSS_THRESHOLD = "round_trip_loss_threshold",
  ADD_TRIM_BUFFER = "add_trim_buffer",
  ADD_TRIM_MAX_ACTIVE_WEIGHT = "add_trim_max_active_weight",
  ADD_TRIM_MIN_OPPORTUNISTIC_SIZE = "add_trim_min_opportunistic_size",
  SELECTION_MODE = "selection_mode",
}

// What is the utility of this because you can't combine enums like this
// TODO: do we want to distniguish between settings and parameters now?
// seems like they are entirely separate things.
export type SettingNameType = ParamNameEnum

export const AnalyticsSettingSchema: ParameterSchemaType = {
  // Configs
  BENCHMARK_SEDOL: "string",
  // Standard Parameters
  RAMP_LENGTH: "number",
  BUY_SELECTION_PERFORMANCE_THRESHOLD: "number",
  BUY_TIMING_PERFORMANCE_THRESHOLD: "number",
  SELL_TIMING_PERFORMANCE_THRESHOLD: "number",
  BUY_SELECTION_HORIZON: "number",
  BUY_TIMING_HORIZON: "number",
  SELL_TIMING_HORIZON: "number",
  MINIMUM_WEIGHT_CHANGE: "number",
  UNDERWATER_DEPTH: "number",
  SMOOTHER: "number",
  UNDERWATER_LIMIT: "number",
  FALLING_KNIFE_DROP: "number",
  FK_LOOKBACK_WINDOW: "number",
  ROUND_TRIP_THRESHOLD: "number",
  ROUND_TRIP_LOSS_THRESHOLD: "number",
  MAX_FLOW_PARAMETER: "number",
  VOLATILITY_LOOKBACK_WINDOW: "number",
}

/**
 * Union type for all the posible settings including Configs, Filters, and Parameters
 */
export type AppliedSettingsType = {
  [index: string]: string | number | object | boolean | Array<string>
}

export type PossibleParamTypes =
  | string
  | number
  | boolean
  | { [key: string]: string[] }
  | { dateFrom: string; dateTo: string }
  | undefined
  | null

export type ParameterSchemaType = Partial<
  Record<keyof typeof ParamNameEnum, PossibleParamTypes>
>

export enum PluginNameEnum {
  ////////// Everywhere //////////
  // Top right of every page
  O_FUND_PERFORMANCE_HORIZONTAL_WATERFALL = "O_FUND_PERFORMANCE_HORIZONTAL_WATERFALL",
  FUND_OVERALL_PERFORMANCE_LINE_AND_IMPACT = "FUND_OVERALL_PERFORMANCE_LINE_AND_IMPACT",
  ANALYTICAL_PERIOD = "ANALYTICAL_PERIOD",

  // Overview Page
  O_BEST_WORST_AREA_CARDS = "O_BEST_WORST_AREA_CARDS",

  ////////// Stock & Stocks Pages //////////
  // Stocks Page
  STOCK_TABLE = "STOCK_TABLE",
  // Stock Page
  STOCK_GRAPH = "STOCK_GRAPH",
  SP_STOCK_HISTORY_TABLE = "SP_STOCK_HISTORY_TABLE",
  SP_STOCK_TABLE_MULTI_STAT_CARD = "SP_STOCK_TABLE_MULTI_STAT_CARD",
  SP_BUY_SELL_SELECTION_TIMING_MULTI_STAT_CARD = "SP_BUY_SELL_SELECTION_TIMING_MULTI_STAT_CARD",
  PORTFOLIO_CONSTITUENT_LOOKUP_TABLE = "PORTFOLIO_CONSTITUENT_LOOKUP_TABLE",

  ////////// Buying - Buy Selection //////////
  BBS_BUY_SELECTION_IMPACT = "BBS_BUY_SELECTION_IMPACT",
  BBS_BUY_SELECTION_IMPACT_DISTRIBUTION = "BBS_BUY_SELECTION_IMPACT_DISTRIBUTION",
  BBS_BUY_SELECTION_IMPACT_PERFORMANCE = "BBS_BUY_SELECTION_IMPACT_PERFORMANCE",
  BBS_BUY_SELECTION_IMPACT_BY_CATEGORY = "BBS_BUY_SELECTION_IMPACT_BY_CATEGORY",
  // Area Card - Buy Selection Area Card
  BBS_AREA_CARD = "BBS_AREA_CARD",
  // Section - Buy Selection Performance
  BBS_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH = "BBS_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH",
  BBS_PERFORMANCE_COLOUR_CODED_TABLE = "BBS_PERFORMANCE_COLOUR_CODED_TABLE",
  BBS_PERFORMANCE_VERTICAL_BAR_GRAPH = "BBS_PERFORMANCE_VERTICAL_BAR_GRAPH",
  BBS_PERFORMANCE_HIT_RATE_WIN_LOSS_STOCK_TABLE = "BBS_PERFORMANCE_HIT_RATE_WIN_LOSS_STOCK_TABLE",

  BBS_PERFORMANCE_STOCK_TABLE = "BBS_PERFORMANCE_STOCK_TABLE",
  BBS_PERFORMANCE_STOCK_TABLE_DISTRIBUTION = "BBS_PERFORMANCE_STOCK_TABLE_DISTRIBUTION",
  BBS_PERFORMANCE_STOCK_TABLE_HIT_RATE = "BBS_PERFORMANCE_STOCK_TABLE_HIT_RATE",
  BBS_PERFORMANCE_STOCK_TABLE_SELECTION_OVER_TIME = "BBS_PERFORMANCE_STOCK_TABLE_SELECTION_OVER_TIME",
  BBS_PERFORMANCE_STOCK_TABLE_WIN_LOSS = "BBS_PERFORMANCE_STOCK_TABLE_WIN_LOSS",
  // Section - Buy Selection Distribution
  BBS_DISTRIBUTION_SCATTER_PLOT = "BBS_DISTRIBUTION_SCATTER_PLOT", // Note: Interaction is supposed to redirect to Stock Page
  BBS_DISTRIBUTION_SELECTION_PERFORMANCE_TABLE = "BBS_DISTRIBUTION_SELECTION_PERFORMANCE_TABLE",
  // Section - Buy Selection By Category
  BBS_HORIZONTAL_STACKED_BAR_GRAPH = "BBS_HORIZONTAL_STACKED_BAR_GRAPH",
  // Section - Buy Selection by Category
  BBS_CATEGORY_PERFORMANCE_TABLE = "BBS_CATEGORY_PERFORMANCE_TABLE",
  BBS_CATEGORY_STOCK_TABLE = "BBS_CATEGORY_STOCK_TABLE",
  ////////// Selling - Sell Timing //////////
  SST_SELL_TIMING_IMPACT = "SST_SELL_TIMING_IMPACT",
  SST_SELL_TIMING_IMPACT_PERFORMANCE = "SST_SELL_TIMING_IMPACT_PERFORMANCE",
  SST_SELL_TIMING_IMPACT_BY_CATEGORY = "SST_SELL_TIMING_IMPACT_BY_CATEGORY",
  // Area Cards
  SST_SOLD_BOTTOM_AREA_CARD = "SST_SOLD_BOTTOM_AREA_CARD",
  SST_SOLD_EARLY_AREA_CARD = "SST_SOLD_EARLY_AREA_CARD",
  SST_SOLD_GREAT_AREA_CARD = "SST_SOLD_GREAT_AREA_CARD",
  SST_SOLD_LATE_AREA_CARD = "SST_SOLD_LATE_AREA_CARD",
  SST_SOLD_ACCEPTABLE_AREA_CARD = "SST_SOLD_ACCEPTABLE_AREA_CARD",
  // Section - Sell Timing Performance
  SST_DISTRIBUTION_TIMING_GRAPH = "SST_DISTRIBUTION_TIMING_GRAPH",
  SST_PERFORMANCE_COLOUR_CODED_TABLE_DEFAULT = "SST_PERFORMANCE_COLOUR_CODED_TABLE_DEFAULT",
  SST_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH = "SST_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH",
  SST_PERFORMANCE_STOCK_TABLE = "SST_PERFORMANCE_STOCK_TABLE",
  SST_COLOUR_CODED_TABLE = "SST_COLOUR_CODED_TABLE",
  // Section - Sell Timing By Category
  SST_CATEGORY_STOCK_TABLE = "SST_CATEGORY_STOCK_TABLE", // Note: Interaction with Bar Graph provides parameters to show in tertiary panel
  SST_HORIZONTAL_STACKED_BAR_GRAPH = "SST_HORIZONTAL_STACKED_BAR_GRAPH",
  SST_CATEGORY_PERFORMANCE_TABLE = "SST_CATEGORY_PERFORMANCE_TABLE",

  SST_DRAW_TOTAL_IMPACT = "SST_DRAW_TOTAL_IMPACT",
  SST_SELL_SNAIL_TRAIL_TOTAL_IMPACT = "SST_SELL_SNAIL_TRAIL_TOTAL_IMPACT",
  // Sell Timing - Draw Down Draw Up
  SST_DRAW_DOWN_FROM_PEAK_GROUPING_TABLE = "SST_DRAW_DOWN_FROM_PEAK_GROUPING_TABLE",
  SST_DRAW_DOWN_FROM_PEAK_SCATTER = "SST_DRAW_DOWN_FROM_PEAK_SCATTER",
  SST_DRAW_DOWN_FROM_PEAK_VERTICAL_STACKED_BAR = "SST_DRAW_DOWN_FROM_PEAK_VERTICAL_STACKED_BAR",
  SST_DRAW_UP_FROM_TROUGH_GROUPING_TABLE = "SST_DRAW_UP_FROM_TROUGH_GROUPING_TABLE",
  SST_DRAW_UP_FROM_TROUGH_SCATTER = "SST_DRAW_UP_FROM_TROUGH_SCATTER",
  SST_DRAW_DOWN_DRAW_UP_STOCK_TABLE = "SST_DRAW_DOWN_DRAW_UP_STOCK_TABLE",
  SST_DRAW_UP_FROM_TROUGH_VERTICAL_STACKED_BAR = "SST_DRAW_UP_FROM_TROUGH_VERTICAL_STACKED_BAR",

  ////////// Value Creation Over Time //////////
  // Multi Stat Card - Value Creation Over Time
  VCOT_MULTI_STAT_CARD = "VCOT_MULTI_STAT_CARD",
  // Section - Holding Period Analysis
  VCOT_HOLDING_PERIOD_INTERACTION_TABLE = "VCOT_HOLDING_PERIOD_INTERACTION_TABLE",
  VCOT_HOLDING_PERIOD_TOTAL_IMPACT = "VCOT_HOLDING_PERIOD_TOTAL_IMPACT",
  VCOT_HOLDING_PERIOD_TOTAL_IMPACT_HOLDING_PERIOD_ANALYSIS_SECTION = "VCOT_HOLDING_PERIOD_TOTAL_IMPACT_HOLDING_PERIOD_ANALYSIS_SECTION",
  VCOT_HOLDING_PERIOD_TOTAL_IMPACT_BUY_DECISIONS_SECTION = "VCOT_HOLDING_PERIOD_TOTAL_IMPACT_BUY_DECISIONS_SECTION",

  VCOT_HOLDING_PERIOD_SNAIL_TRAIL_AGGREGATE = "VCOT_HOLDING_PERIOD_SNAIL_TRAIL_AGGREGATE",
  VCOT_HOLDING_PERIOD_SNAIL_TRAIL_BREAKDOWN = "VCOT_HOLDING_PERIOD_SNAIL_TRAIL_BREAKDOWN",
  VCOT_HOLDING_PERIOD_SCATTER_PLOT = "VCOT_HOLDING_PERIOD_SCATTER_PLOT",
  VCOT_HOLDING_PERIOD_TABLE = "VCOT_HOLDING_PERIOD_TABLE",
  VCOT_BUY_DECISIONS_OVER_TIME_BAR_GRAPH = "VCOT_BUY_DECISIONS_OVER_TIME_BAR_GRAPH",
  VCOT_BUY_DECISIONS_OVER_TIME_TABLE = "VCOT_BUY_DECISIONS_OVER_TIME_TABLE",
  VCOT_BUY_DECISIONS_OVER_TIME_VERTICAL_BAR_GRAPH = "VCOT_BUY_DECISIONS_OVER_TIME_VERTICAL_BAR_GRAPH",
  VCOT_BUY_DECISIONS_OVER_TIME_TABLE_HIT_RATE_WIN_LOSS_TABLE = "VCOT_BUY_DECISIONS_OVER_TIME_TABLE_HIT_RATE_WIN_LOSS_TABLE",
  VCOT_BUY_DECISIONS_OVER_TIME_TABLE_SELECTION_OVER_TIME = "VCOT_BUY_DECISIONS_OVER_TIME_TABLE_SELECTION_OVER_TIME",
  VCOT_BUY_DECISIONS_OVER_TIME_TABLE_HIT_RATE_WIN_LOSS_TABLE_SELECTION_OVER_TIME = "VCOT_BUY_DECISIONS_OVER_TIME_TABLE_HIT_RATE_WIN_LOSS_TABLE_SELECTION_OVER_TIME",

  ////////// Weighting //////////
  // Area Cards
  W_INITIAL_WEIGHTING_AREA_CARD = "W_INITIAL_WEIGHTING_AREA_CARD",
  W_ADD_TRIM_AREA_CARD = "W_ADD_TRIM_AREA_CARD",
  // Section - Weighting of Adds and Trims
  W_ADD_TRIM_TOTAL_IMPACT = "W_ADD_TRIM_TOTAL_IMPACT",
  W_ADD_TRIM_EVENTS_BAR_CHART = "W_ADD_TRIM_EVENTS_BAR_CHART",
  W_ADD_TRIM_EVENTS_TABLE = "W_ADD_TRIM_EVENTS_TABLE",
  W_ADD_TRIM_EVENTS_INTERACTION_TABLE = "W_ADD_TRIM_EVENTS_INTERACTION_TABLE",
  W_ADD_TRIM_TABLE_WITH_ICONS_ADD = "W_ADD_TRIM_TABLE_WITH_ICONS_ADD",
  W_ADD_TRIM_TABLE_WITH_ICONS_TRIM = "W_ADD_TRIM_TABLE_WITH_ICONS_TRIM",
  W_ADD_TRIM_EVENT_GRAPH_ADD = "W_ADD_TRIM_EVENT_GRAPH_ADD",
  W_ADD_TRIM_EVENT_GRAPH_TRIM = "W_ADD_TRIM_EVENT_GRAPH_TRIM",
  // Section - Initial Weighting
  W_INITIAL_TOTAL_IMPACT = "W_INITIAL_TOTAL_IMPACT",
  W_INITIAL_SCATTER_PLOT = "W_INITIAL_SCATTER_PLOT",
  W_INITIAL_SIMPLE_TABLE = "W_INITIAL_SIMPLE_TABLE",
  W_INITIAL_INTERACTION_STOCK_TABLE = "W_INITIAL_INTERACTION_STOCK_TABLE",
  // Section - Weighting Across the Journey
  W_JOURNEY_TOTAL_IMPACT = "W_JOURNEY_TOTAL_IMPACT",
  W_JOURNEY_SCATTER_PLOT = "W_JOURNEY_SCATTER_PLOT",
  W_JOURNEY_SIMPLE_TABLE = "W_JOURNEY_SIMPLE_TABLE",
  W_JOURNEY_INTERACTION_STOCK_TABLE = "W_JOURNEY_INTERACTION_STOCK_TABLE",

  ////////// Portfolio Construction //////////
  // Portfolio Construction - Performance
  PC_CATEGORY_EXPOSURE_OVER_TIME_BAR_CHART = "PC_CATEGORY_EXPOSURE_OVER_TIME_BAR_CHART",

  PC_CATEGORY_EXPOSURE_TABLE = "PC_CATEGORY_EXPOSURE_TABLE",
  PC_CATEGORY_EXPOSURE_TABLE_SELECTION = "PC_CATEGORY_EXPOSURE_TABLE_SELECTION",
  PC_CATEGORY_EXPOSURE_TABLE_ALLOCATION = "PC_CATEGORY_EXPOSURE_TABLE_ALLOCATION",

  PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE = "PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE",
  PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_SELECTION = "PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_SELECTION",
  PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_ALLOCATION = "PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_ALLOCATION",

  PC_CATEGORY_EXPOSURE_PERIOD_TABLE = "PC_CATEGORY_EXPOSURE_PERIOD_TABLE",
  PC_PERFORMANCE_LINE_GRAPH = "PC_PERFORMANCE_LINE_GRAPH",
  PC_PERFORMANCE_RISK_ADJUSTED_LINE_GRAPH = "PC_PERFORMANCE_RISK_ADJUSTED_LINE_GRAPH",
  // Portfolio Construction - Risk
  PC_RISK_PORTFOLIO_CORRELATION_LINE_GRAPH = "PC_RISK_PORTFOLIO_CORRELATION_LINE_GRAPH",
  PC_RISK_VOLATILITY_LINE_GRAPH = "PC_RISK_VOLATILITY_LINE_GRAPH",
  PC_RISK_BENCHMARK_CORRELATION_LINE_GRAPH = "PC_RISK_BENCHMARK_CORRELATION_LINE_GRAPH",
  // Section - Allocation
  PC_RETURN_ATTRIBUTION_TOTAL_IMPACT = "PC_RETURN_ATTRIBUTION_TOTAL_IMPACT",
  PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_SELECTION = "PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_SELECTION",
  PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_ALLOCATION = "PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_ALLOCATION",

  PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART = "PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART",
  PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_SELECTION = "PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_SELECTION",
  PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_ALLOCATION = "PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_ALLOCATION",

  PC_RETURN_ATTRIBUTION_TABLE = "PC_RETURN_ATTRIBUTION_TABLE",
  PC_RETURN_ATTRIBUTION_TABLE_SELECTION = "PC_RETURN_ATTRIBUTION_TABLE_SELECTION",
  PC_RETURN_ATTRIBUTION_TABLE_ALLOCATION = "PC_RETURN_ATTRIBUTION_TABLE_ALLOCATION",

  PC_CATEGORY_EXPOSURE_PERIOD_TABLE_SELECTION = "PC_CATEGORY_EXPOSURE_PERIOD_TABLE_SELECTION",
  PC_CATEGORY_EXPOSURE_PERIOD_TABLE_ALLOCATION = "PC_CATEGORY_EXPOSURE_PERIOD_TABLE_ALLOCATION",

  ////////// Special Situations - Area Cards //////////
  SSUW_UNDERWATER_AREA_CARD = "SSUW_UNDERWATER_AREA_CARD",
  SSRT_ROUNDTRIP_AREA_CARD = "SSRT_ROUNDTRIP_AREA_CARD",

  ////////// Metadata //////////
  CATEGORIES = "CATEGORIES",
}

export enum SectionNameEnum {
  ////////// Overview Page (3) //////////
  O_FUND_PERFORMANCE = "O_FUND_PERFORMANCE",
  ////////// Portfolio Construction Page (4) //////////
  PC_EXPOSURE_OVER_TIME = "PC_EXPOSURE_OVER_TIME",
  PC_PERFORMANCE = "PC_PERFORMANCE",
  PC_RISK = "PC_RISK",
  PC_SELECTION = "PC_SELECTION",
  PC_ALLOCATION = "PC_ALLOCATION",

  ////////// Weighting Page (5) //////////
  W_ADDS_AND_TRIMS_AREA_CARD = "W_ADDS_AND_TRIMS_AREA_CARD",
  W_WEIGHTING_OF_ADDS_AND_TRIMS = "W_WEIGHTING_OF_ADDS_AND_TRIMS",
  W_INITIAL_WEIGHTING = "W_INITIAL_WEIGHTING",
  W_WEIGHTING_ACROSS_THE_JOURNEY = "W_WEIGHTING_ACROSS_THE_JOURNEY",

  ////////// Buying - Buy Selection Page (4) //////////
  BBS_SELECTION_AREA_CARD = "BBS_SELECTION_AREA_CARD",
  BBS_BUY_SELECTION_PERFORMANCE = "BBS_BUY_SELECTION_PERFORMANCE",
  BBS_BUY_SELECTION_DISTRIBUTION = "BBS_BUY_SELECTION_DISTRIBUTION",
  BBS_SELECTION_BY_CATEGORY = "BBS_SELECTION_BY_CATEGORY",

  ////////// Buying - Buy Timing Page (6) //////////
  BBT_BOUGHT_LATE_AREA_CARD = "BBT_BOUGHT_LATE_AREA_CARD",
  BBT_BOUGHT_EARLY_AREA_CARD = "BBT_BOUGHT_EARLY_AREA_CARD",
  BBT_BOUGHT_AT_TOP_AREA_CARD = "BBT_BOUGHT_AT_TOP_AREA_CARD",
  BBT_BUY_TIMING_AREA_CARD = "BBT_BUY_TIMING_AREA_CARD",
  BBT_BUY_TIMING_PERFORMANCE = "BBT_BUY_TIMING_PERFORMANCE",
  BBT_BUY_TIMING_BY_CATEGORY = "BBT_BUY_TIMING_BY_CATEGORY",

  ////////// Selling - Sell Timing (6) //////////
  SST_SOLD_BOTTOM_AREA_CARD = "SST_SOLD_BOTTOM_AREA_CARD",
  SST_SOLD_EARLY_AREA_CARD = "SST_SOLD_EARLY_AREA_CARD",
  SST_SOLD_GREAT_AREA_CARD = "SST_SOLD_GREAT_AREA_CARD",
  SST_SOLD_LATE_AREA_CARD = "SST_SOLD_LATE_AREA_CARD",
  SST_SOLD_ACCEPTABLE_AREA_CARD = "SST_SOLD_ACCEPTABLE_AREA_CARD",
  SST_SELL_TIMING_PERFORMANCE = "SST_SELL_TIMING_PERFORMANCE",
  SST_SELL_TIMING_BY_CATEGORY = "SST_SELL_TIMING_BY_CATEGORY",
  SST_NEW_SELL_TIMING = "SST_NEW_SELL_TIMING",
  SST_SELL_SNAIL = "SST_SELL_SNAIL",
  SST_PEAKS_AND_TROUGHS = "SST_PEAKS_AND_TROUGHS",

  ////////// Value Creation Over Time Page (3) //////////
  VCOT_MULTI_STAT_CARD = "VCOT_MULTI_STAT_CARD",
  VCOT_HOLDING_PERIOD_ANALYSIS = "VCOT_HOLDING_PERIOD_ANALYSIS",
  BUY_DECISIONS_OVER_TIME = "BUY_DECISIONS_OVER_TIME",

  ////////// Special Situations - Overview //////////
  SSO_FALLING_KNIVES_AREA_CARD = "SSO_FALLING_KNIVES_AREA_CARD",
  SSO_ROUND_TRIPS_AREA_CARD = "SSO_ROUND_TRIPS_AREA_CARD",
  SSO_UNDERWATER_AREA_CARD = "SSO_UNDERWATER_AREA_CARD",

  ////////// Special Situations - Falling Knives //////////
  SSFK_FALLING_KNIVES_AREA_CARD = "SSFK_FALLING_KNIVES_AREA_CARD",

  ////////// Special Situations - Round Trips //////////
  SSRT_ROUND_TRIPS_AREA_CARD = "SSRT_ROUND_TRIPS_AREA_CARD",

  ////////// Special Situations - Underwater //////////
  SSU_UNDERWATER_AREA_CARD = "SSU_UNDERWATER_AREA_CARD",

  ////////// Stocks Page & Stock Page(s) (2) //////////
  STOCK_TABLE = "STOCK_TABLE",
  STOCK_GRAPH = "STOCK_GRAPH",
  SP_STOCK_HISTORY_TABLE = "SP_STOCK_HISTORY_TABLE",
  SP_STOCK_TABLE_MULTI_STAT_CARD = "SP_STOCK_TABLE_MULTI_STAT_CARD",
  SP_BUY_SELL_SELECTION_TIMING_MULTI_STAT_CARD = "SP_BUY_SELL_SELECTION_TIMING_MULTI_STAT_CARD",
}

// These are param values that must be sent to the backend as -1 insted of their normal values
export enum SpecialParamValues {
  UNTIL_SELL = "Until Sell",
}

export enum SplitRequestEnum {
  SPLIT_A = "splitA",
  SPLIT_B = "splitB",
  STANDARD = "standard",
}

export enum AddsTrimsEnum {
  ADDS = "ADDS",
  TRIMS = "TRIMS",
}

export enum AllocationOrSelectionEnum {
  ALLOCATION = "ALLOCATION",
  SELECTION = "SELECTION",
}

export type ApiOrganizationPayload = {
  id?: IdType
  name: string
  alias: string
  users: { id?: IdType; email: string; role: UserOrgRoleEnum }[]
}

export type ApiPortfolioPayload = {
  name: string
  description?: string
  client: { id: IdType }
}

export enum AxisFormatEnum {
  PERCENTAGE = "PERCENTAGE",
  DATE = "DATE",
  TRIM = "TRIM",
  NUMBER = "NUMBER",
  MONTHS = "MONTHS",
}

export type OrganizationFormType = {
  id?: IdType
  name: string
  type?: SelectOptionType
  otherType?: string
  alias?: string
  users?: OrganizationFormUserType[]
}

export type OrganizationFormUserType = {
  id?: IdType
  email: string
  role: {
    label: string
    value: UserOrgRoleEnum
  }
}

export enum UserOrgRoleEnum {
  AccountManager = "AccountManager",
  Owner = "Owner",
  Contributor = "Contributor",
  Member = "Member",
}

export type UserToOrganizationType = {
  id: number
  userId: number
  user?: UserType
  organizationId: number
  organization?: OrganizationType
  role: UserOrgRoleEnum
  jobTitle?: string
}

export type CommonCustomizationType = {
  id: IdType
  type: CustomizationTypesEnum
  value: unknown
  portfolioId?: IdType
  userId: IdType
}

export type CustomizationType =
  | StockTableColumnsCustomizationType
  | CommonCustomizationType

export enum CustomizationTypesEnum {
  STOCK_TABLE_COLUMNS = "STOCK_TABLE_COLUMNS",
}

export type DataItemType = {
  [key: string]:
    | string
    | number
    | {
        data?: {
          [key: string]: string | number
        }
      }
}

export enum DrawDownOrDrawUpEnum {
  SST_DRAW_DOWN_FROM_PEAK = "SST_DRAW_DOWN_FROM_PEAK",
  SST_DRAW_UP_FROM_TROUGH = "SST_DRAW_UP_FROM_TROUGH",
}

export enum HitRateOrWinLossEnum {
  HIT_RATE_OVER_TIME = "HIT_RATE_OVER_TIME",
  WIN_LOSS_OVER_TIME = "WIN_LOSS_OVER_TIME",
}

export type IdType = number

export type NavigationLinkType = {
  label: string
  href: string
}

export enum PerformanceOrImpactEnum {
  PERFORMANCE = "PERFORMANCE",
  IMPACT = "IMPACT",
}

export enum TimeGranularityEnum {
  Monthly = "monthly",
  Annually = "annually",
  Quarterly = "quarterly",
  Biannually = "biannually",
}

export type PortfolioFormType = {
  id?: IdType
  name: string
  description?: string
  fileUpload?: Partial<File> | null
  client: { label?: string; value: IdType } | null
  productPolygon?: ProductPolygonType
}

export enum PortfolioOrActiveWeightEnum {
  PORTFOLIO_WEIGHT = "PORTFOLIO_WEIGHT",
  ACTIVE_WEIGHT = "ACTIVE_WEIGHT",
}

export enum ProductStatusEnum {
  Draft = "Draft",
  Ready = "Ready",
}

export type ProductPolygonType = {
  id: number
  portfolioId: number
  status: ProductStatusEnum
  uploadUrl?: string // url to upload GDS
}

export type PortfolioConfigType = {
  id?: IdType
  status: ProductStatusEnum
}

export enum UserProductPermissionEnum {
  View = "View",
  Edit = "Edit",
  None = "None",
}

export type ViewOrEditPermissionType =
  | UserProductPermissionEnum.View
  | UserProductPermissionEnum.Edit

export type UserToPortfolioPermissionType = {
  id: number
  userId: number
  portfolioId: number
  polygonPermission: UserProductPermissionEnum
  vertexPermission: UserProductPermissionEnum
}

export type OrganizationType = {
  id: IdType
  name: string
  type: OrgTypeEnum | string
  alias?: string
  logo?: string
  uploadLogoUrl?: string
  portfolios: PortfolioType[]
  organizationUsers: UserToOrganizationType[]
  users: UserType[]
  createdAt: string
  updatedAt: string
  deletedAt?: string
}

export type PortfolioType = {
  id: number
  clientId: number
  client: OrganizationType
  name: string
  description?: string
  icon?: string
  uploadIconUrl?: string
  usersPermissions?: UserType[]
  productVertex: ProductVertexType | null
  productPolygon: ProductPolygonType | null
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
}

export type AccessiblePortfolioType = {
  id: number
  clientId: number
  client: OrganizationType
  name: string
  description?: string
  icon?: string
  userPermission?: UserToPortfolioPermissionType
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
}

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp]

export enum RolesEnum {
  ADMIN = "ADMIN",
  SADMIN = "SADMIN",
  ADVISORY = "ADVISORY",
  USER = "USER",
}

export type SectionType = {
  id: IdType
  name?: string
  utilName?: string
  responseType?: string
  type?: string
}

export type SelectedAnalyticsSettingType = string

export enum SelectionOrAllocationTabEnum {
  TAB_1_IMPACT = "TAB_1_IMPACT",
  TAB_2_IMPACT = "TAB_2_IMPACT",
}

// Passed as params to the plugins
export enum SelectionOrAllocationTabValuesEnum {
  SELECTION_IMPACT = "SELECTION_IMPACT",
  INTERACTION_IMPACT = "INTERACTION_IMPACT",
  ALLOCATION_IMPACT = "ALLOCATION_IMPACT",
  BENCHMARK_CONTRIBUTION = "BENCHMARK_CONTRIBUTION",
}

export type SettingOptionType = { label: string; value: string | number }

export type SettingMetadata = {
  default: PropType<SettingOptionType, "value">
  title: string
  unit: string
  use_type: SettingUseTypeEnum
  values: Array<SettingOptionType>
  name: ParamNameEnum
}

export type SettingNameKey = ParamNameEnum | string

export type SettingsResponseType = {
  metadata: Record<SettingNameKey, SettingMetadata>
  parameters: Record<SettingNameKey, PropType<SettingMetadata, "default">>
}

export type CategoryType = {
  name: string
  values: string[]
}

export type CategoriesResponseType = Array<CategoryType>

export enum SettingUseTypeEnum {
  PARAM = "PARAM",
  // TODO: remove this
  FILTER = "FILTER",
  CONFIG = "CONFIG",
  TOGGLE = "TOGGLE",
}

export enum SkewEnum {
  HeavyLeftSkew = "HeavyLeftSkew",
  LeftSkew = "LeftSkew",
  CenterSkew = "CenterSkew",
  RightSkew = "RightSkew",
  HeavyRightSkew = "HeavyRightSkew",
}

export type ProductVertexType = {
  id: number
  portfolioId: number
  portfolio?: PortfolioType
  status: ProductStatusEnum
  // Temporary types to avoid copy pasting lots of types that we're not going to use because we shouldn't return the whole productVertex in Polygon and vice versa
  columns?: Array<unknown>
  questionSets?: unknown[]
  trackerStocks?: unknown[]
}

export type StockTableColumnsCustomizationType = {
  id: IdType
  type: CustomizationTypesEnum.STOCK_TABLE_COLUMNS
  portfolioId: IdType | null
  userId: IdType | null
  value: string[] | null
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export type UserType = {
  id: number
  email: string
  firstName: string
  lastName: string
  profilePicture?: string
  organizations: OrganizationType[]
  accessiblePortfolios: AccessiblePortfolioType[]
  uploadAvatarUrl?: string
  jobTitle?: string
  userToPortfolioPermissions?: UserToPortfolioPermissionType
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export enum KeyMetricFormatEnum {
  STRING = "STRING",
  PERCENTAGE = "PERCENTAGE",
  NUMBER = "NUMBER",
  TRIM = "TRIM",
}

export type KeyMetricValueType = string | number

export enum KeyMetricTitleEnum {
  HitRate = "Hit Rate",
  WinLossRate = "Win Loss Rate",
  SuboptimalImpact = "Suboptimal Portfolio Impact",
  BuySelectionImpact = "Buy Selection Impact",
  InvestmentHitRate = "Investment Hit Rate",
  InvestmentWinLossRate = "Investment Win Loss Rate",
  AddsAndTrimsImpact = "Adds and Trims Impact",
  Adds = "Adds",
  Trims = "Trims",
}

export type ValueOf<T> = T[keyof T]

// TODO: We should be using the types from the pluginResponseTypes.ts file ideally, but this is a bigger excercise and requires changes in the component library
export interface ScatterPlotDataItem extends ScatterPlotDataItemType {
  asset_id?: string
  name?: string
  filled?: boolean
  monthsPostBuy?: string
  buyDate?: string
  sellDate?: string
}

export type HorizontalWaterfallItemType = {
  value: string | number | null
  label: string
}

export type InjectedParamsType = {
  [paramName: string]: string | number | null
}

export type CurrentAnalyticsSettingsType = {
  [key in SettingNameType]?:
    | number
    | string
    | boolean
    | SelectedAnalyticsSettingType
}

export type TimingGraphDataPointType = TimingGraphDataPoint & {
  asset_id?: string
  buyDate?: string
  sellDate?: string
}

export type VerticalBarDataItemType = VerticalBarDataItemTypeLib & {
  period?: string
}

export type StockTableItemType = StockTableItemTypeFromPluginInterface

export type BreadCrumbType = {
  label: string
  href: string
  active: boolean
  disabled?: boolean
}

export enum OrgTypeEnum {
  FamilyOffice = "Family Office",
  HedgeFund = "Hedge Fund",
  InstitutionalManager = "Institutional Manager",
  InsuranceCompany = "Insurance Company",
  PensionFund = "Pension Fund",
  SovereignFund = "Sovereign Fund",
  WealthManager = "Wealth Manager",
  Other = "Other",
}

export type S3FieldsType = {
  key: string
  AWSAccessKeyId: string
  policy: string
  signature: string
}

export enum RequestChangesTypeEnum {
  UpdateUserProfile = "UpdateUserProfile",

  CreatePortfolio = "CreatePortfolio",
  EditPortfolio = "EditPortfolio",
  DeletePortfolio = "DeletePortfolio",

  EditOrganization = "EditOrganization",
  DeleteOrganization = "DeleteOrganization",

  CreateUserAndInviteToOrganization = "CreateUserAndInviteToOrganization",
  RemoveUserFromOrganization = "RemoveUserFromOrganization",
  InviteUserToOrganization = "InviteUserToOrganization",

  RemovePortfolioFromPolygon = "RemovePortfolioFromPolygon",
  UpdatePolygonSettings = "UpdatePolygonSettings",
  InviteUserToPolygon = "InviteUserToPolygon",
  ChangeUserPermissionInPolygon = "ChangeUserPermissionInPolygon",
  RequestAccessToPage = "RequestAccessToPage",
}

export type FilterStateType = {
  selectedFilters: { [key: string]: string[] }
  appliedFilters: { [key: string]: string[] }
}

export type PresignedUrlAndFilename = {
  url: string
  filename: string
}

export type SelectionItemType = {
  selectTag: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterFn: d3.ValueFn<d3.BaseType, any, boolean>
  filterOnNodes?: boolean
  // Nested select is used for HorizontalStackedBarGraph  and VerticalStackedBarGraph interactions, when click on visualization should highlight only one rect inside the whole bar
  nestedSelect?: {
    selectTag: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterFn: d3.ValueFn<d3.BaseType, any, boolean>
    filterOnNodes?: boolean
  }
}

export enum ContactEmailTypeEnum {
  ProductFeedback = "ProductFeedback",
  ReportBug = "ReportBug",
  AskQuestion = "AskQuestion",
}
