import * as React from "react"

import { useSelector } from "react-redux"

import { AppState } from "src/redux/rootReducer"

type SelectorFabriqueType<Arg, Res> = () => (state: AppState, args: Arg) => Res

export const useSelectorWithArguments = <Arg, Res>(
  selector: SelectorFabriqueType<Arg, Res>,
  args: Arg,
) => {
  const memoizedSelector = React.useMemo(selector, [])

  const selectorValue = useSelector((state: AppState) =>
    memoizedSelector(state, args),
  )

  return selectorValue
}
